<template>
  <div>
    <!-- <div class="navinfo d-none d-md-block" v-if="$route.name !== 'approve'">
      <v-container class="pt-5">
        <v-row>
          <div class="ml-1">
            <v-icon color="white">mdi-map-marker</v-icon>
            <span class="ml-3 white--text caption">131 West 33rd St. unit 10D, New York NY-10001</span>
          </div>
          <div class="ml-5">
            <v-icon color="white">mdi-phone</v-icon>
            <span class="ml-3 white--text caption">1 (703) 839-3192</span>
          </div>
          <div class="ml-5">
            <v-icon color="white">mdi-email-open</v-icon>
            <span class="ml-3 white--text caption">support@atsfares.com</span>
          </div>
        </v-row>
      </v-container>
    </div> -->
    <v-app-bar
    dark
    color="white"
    elevation="1"
    style="height: 80px;"
    scroll-off-screen
    >
      <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row align="center" class="pb-2">
            <v-btn icon @click="drawer = !drawer"
            class="d-flex d-md-none mt-3"
            >
              <v-icon large color="secondary">mdi-menu</v-icon>
            </v-btn>
            <v-col cols="2" class="pb-4">

              <router-link style="text-decoration: none;" to="/">
                <img src="../static/magi.png" class="mt-2" alt="magi logo" style="width: 60px;">
              </router-link>

            </v-col>

            <div class="d-flex">
              <v-btn
              v-for="tab in tabs"
              :key="tab.title"
              exact
              :to="{ name: tab.route} "
              :text="$route.name !== tab.title"
              :elevation="$route.name == tab.title ? 2 : 0"
              :tile="$route.name == tab.title" :color="$route.name == tab.title ? 'white' : 'secondary'"
              :height="85"
              :large="$vuetify.breakpoint.lgAndUp"
              class="d-none d-md-flex"
              >
                  <v-icon color="secondary">{{tab.icon}}</v-icon>
                  <span class="body-1 ml-2 font-weight-medium blueDark--text">{{tab.title}}</span>
              </v-btn>
              <v-btn v-if="user.permissions.includes('can-see-contracts')" class="d-none d-md-flex mt-3" color="blueDark" @click="goTo('commissions')" height="60" elevation="0" text :large="$vuetify.breakpoint.lgAndUp"><span class="body-1 ml-2 font-weight-medium">Comissions</span></v-btn>
            </div>

            <v-spacer></v-spacer>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="py-2 d-none d-md-block"
                color="secondary"
                dark
                v-bind="attrs"
                outlined
                large
                v-on="on"
                >
                  <v-icon left>mdi-cash</v-icon> {{currency.code}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in currencies"
                :key="i"
                @click="$store.dispatch('setCurrency', item)"
                >
                  <v-list-item-title>{{ item.code }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <router-link class="text-decoration-none d-none d-sm-flex" :to="{ name: 'login'} " v-if="!$cookies.isKey('userToken')">
              <v-btn
              elevation="0"
              :large="$vuetify.breakpoint.lgAndUp"
              :small="$vuetify.breakpoint.mdAndDown"
              color="secondary"
              height="47"
              class="px-10 ml-1"
              >
                <span>Login</span>
                <v-icon right>mdi-login-variant</v-icon>
              </v-btn>
            </router-link>

            <v-menu offset-y v-else class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-btn
                color="white --text"
                text
                v-on="on"
                >
                  <v-avatar color="secondary" class="mr-2" size="40">
                    <span class="white--text body-1">
                      {{user.name.charAt(0).toUpperCase()}}
                    </span>
                  </v-avatar>
                  <span class="d-none d-sm-flex secondary--text">{{user.name.substr(0, user.name.indexOf(' '))}}</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link exact :to="{ name: 'profile' }">
                  <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-row>
      </v-container>

    </v-app-bar>

    <v-navigation-drawer
    v-model="drawer"
    absolute
    color="white"
    dark
    temporary
    >
      <v-list
      nav
      dense
      >
        <v-list-item-group
        v-model="group"
        active-class="secondary--text text-accent-4"
        >
          <v-list-item link exact to="/">
            <v-img src="../static/magi.png" alt="magi travel logo" max-width="50px"></v-img>
          </v-list-item>

          <v-list-item two-line link exact :to="{ name: 'login' }" v-if="!$cookies.isKey('userToken')">
            <v-list-item-avatar>
              <v-icon color="secondary">mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">Login / Register</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line link exact :to="{ name: 'profile' }" v-else>
            <v-list-item-avatar v-if="user !== null">
              <v-avatar color="secondary" class="text-center" size="32">

                  <span class="white--text body-1">
                    {{user.name.charAt(0).toUpperCase()}}
                  </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>

              <v-list-item-title v-cloak class="secondary--text">{{user.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item active-class="'active-route-blue" link exact :to="{ name: item.route }" v-for="item in tabs" :key="item.title">
              <v-list-item-title :class="$route.name === item.title ? 'white--text' : 'secondary--text'">
                <v-icon small :color="$route.name === item.title ? 'white' : 'secondary'">{{item.icon}}</v-icon>
                {{item.title}}
              </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="user.permissions.includes('can-see-contracts')">
            <v-btn @click="goTo('commissions')" text x-small color="secondary">Commissions</v-btn>
          </v-list-item>

          <v-list-item>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="py-2"
                color="secondary"
                dark
                v-bind="attrs"
                outlined
                large
                v-on="on"
                >
                  <v-icon left>mdi-cash</v-icon> {{currency.code}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in currencies"
                :key="i"
                @click="$store.dispatch('setCurrency', item)"
                >
                  <v-list-item-title>{{ item.code }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { logout, headers } from '../links'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      drawer: false,
      group: null,
      tabs: [
        { title: 'flights', route: 'flights', icon: 'mdi-airplane' },
        { title: 'hotels', route: 'hotels', icon: 'mdi-domain' },
        { title: 'cars', route: 'cars', icon: 'mdi-car' }
      ],
      links: [
        { title: 'home', route: 'Home' },
        { title: 'trips', route: 'trips' },
        { title: 'about us', route: 'about' },
        { title: 'rewards', route: 'rewards' }
      ],
      user: null
    }
  },
  computed: {
    userStore () {
      return this.$store.state.user
    },
    ...mapState(['currency', 'currencies'])
  },
  watch: {
    userStore (newVal) {
      this.user = newVal
    },
    group () {
      this.drawer = false
    }
  },
  methods: {
    goTo (link) {
      let baseUrl
      if (link === 'commissions') {
        baseUrl = process.env.NODE_ENV === 'production' ? 'https://atsfares.com/commissions/index.php' : 'https://test.atsfares.com/commissions/index.php'
        window.open(baseUrl, '_blank')
      } else this.$router.push({ name: link })
    },
    logout () {
      this.$http.post(logout, null, { headers: headers(this.$cookies.get('userToken')) }).then(() => {
        this.$store.dispatch('removeUser')
        this.$cookies.remove('userToken')
        localStorage.removeItem('userId')
        localStorage.removeItem('token')
        this.$router.push({ name: 'login' })
      })
    }
  },
  created () {
    this.user = this.$store.state.user
  }
}
</script>

<style>
  [v-cloak] { display: none; }
  .subNav {
    overflow-x: auto;
  }
  /* .v-toolbar {
    height: 143px !important;
  } */
  .navinfo {
    background-color: #325dbe;
  }
  .v-toolbar__content {
    height: 95px !important;
  }
  .v-toolbar__extension {
    background-color: #325AAF;
  }
  .active-route {
    background-color: #E28134;
    border-radius: 5PX;
    height: 58px;
  }
  .active-route-blue {
    background-color: #150958;
    border-radius: 5PX;
    height: 58px;
  }
</style>
