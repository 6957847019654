import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'
import titleMixin from './mixins/titleMixin'
import VueCookies from 'vue-cookies'
import * as VueGoogleMaps from 'vue2-google-maps'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
// import VueFbCustomerChat from 'vue-fb-customer-chat'
import { currencies } from './links.js'

Vue.prototype.window = window

Vue.config.productionTip = false

const url = currencies
fetch(url).then(response => response.json()).then(response => {
  if (response.status) {
    store.dispatch('setCurrencies', response.data)
  }
  // if (process.env.VUE_APP_DEFAULT_CURRENCY) {
  //   store.dispatch('setCurrency', response.data.find(item => item.code === process.env.VUE_APP_DEFAULT_CURRENCY))
  // }
})

Vue.filter('changeCurrency', function (price) {
  const defaultRate = {
    code: 'USD',
    countryName: 'UNITED STATES',
    rate: 1,
    name: 'DOLLAR'
  }
  const finalPrice = (price / defaultRate.rate) * store.state.currency.rate
  return store.state.currency.code + ' ' + finalPrice.toFixed(2)
})

Vue.filter('formatDate', function (date) {
  date.replace(/-/g, '/')
  return new Date(date).toDateString()
})

Vue.component('date-picker', DatePicker)

// Vue.use(rtdbPlugin)
// Vue.use(VueFbCustomerChat, {
//   page_id: 100947741485424,
//   theme_color: '#2F80C2',
//   locale: 'en_US'
// })
Vue.use(VueResource)
Vue.mixin(titleMixin)
Vue.use(VueCookies)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCMpLmI6ZbrtqkEA_hIP7aWCJvRsCLz11c',
    libraries: 'places' // necessary for places input
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
