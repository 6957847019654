import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "flights" */ '../views/flights.vue')
  },
  {
    path: '/flights',
    name: 'flights',
    component: () => import(/* webpackChunkName: "flights" */ '../views/flights.vue')
  },
  {
    path: '/flightResults',
    name: 'flightResults',
    component: () => import(/* webpackChunkName: "flightsResults" */ '../views/flightResults.vue')
  },
  {
    path: '/book/:id/:search/:relatedFlightId',
    name: 'book',
    component: () => import(/* webpackChunkName: "flightsBook" */ '../views/book.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import(/* webpackChunkName: "verify" */ '../views/verify.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "login" */ '../views/register.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/resetPassword.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile.vue')
  },
  {
    path: '/hotelsResults',
    name: 'hotelsResults',
    component: () => import(/* webpackChunkName: "hotelsResults" */ '../views/hotelsResults.vue')
  },
  {
    path: '/hotels',
    name: 'hotels',
    component: () => import(/* webpackChunkName: "hotels" */ '../views/hotels.vue')
  },
  {
    path: '/hotel/:sessionId/:hotelIndex/:hotelCode',
    name: 'oneHotel',
    component: () => import(/* webpackChunkName: "hotelDetails" */ '../views/oneHotel.vue')
  },
  {
    path: '/bookhotel',
    name: 'hotelBook',
    component: () => import(/* webpackChunkName: "hotelBook" */ '../views/hotelBook.vue')
  },
  {
    path: '/cars',
    name: 'cars',
    component: () => import(/* webpackChunkName: "cars" */ '../views/cars.vue')
  },
  {
    path: '/carsResults',
    name: 'carsResults',
    component: () => import(/* webpackChunkName: "carsResults" */ '../views/carsResults.vue')
  },
  {
    path: '/carBook/:searchId/:carId',
    name: 'carsBook',
    component: () => import(/* webpackChunkName: "carsBook" */ '../views/carBook.vue')
  },
  {
    path: '/trips',
    name: 'trips',
    component: () => import(/* webpackChunkName: "trips" */ '../views/trips.vue')
  },
  {
    path: '/trip',
    name: 'trip',
    component: () => import(/* webpackChunkName: "trip" */ '../views/trip.vue')
  },
  {
    path: '/approve',
    name: 'approve',
    component: () => import(/* webpackChunkName: "approve" */ '../views/approve.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '../components/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (
    to.name !== 'login' &&
    to.name !== 'verify' &&
    to.name !== 'resetPassword' &&
    to.name !== 'approve' &&
    to.name !== 'register' &&
    !localStorage.getItem('token')
  ) {
    next({ name: 'login' })
  } else next()
})
export default router
